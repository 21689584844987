import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroSection = ({ title, heroImage, author, date, tags }) => (
  <>
    <section className="min-h-[60vh] flex items-center">
      <div className="container mx-auto max-w-6xl px-6 py-10 space-y-8">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <h1 className="font-medium text-5xl leading-normal tracking-tighter">
            {title}
          </h1>
        </div>
        <div className="flex text-gray-400 space-x-4 items-center">
          <span>by {author}</span>
          <div className="w-0.5 h-4 bg-gray-400"></div>
          <span>{date}</span>
        </div>
        <div className="flex space-x-6">
          {tags.map((tag, index) => (
            <div
              key={`single-news-tag-${index}`}
              className="select-none border-b-2 border-blue-100"
            >
              {tag}
            </div>
          ))}
        </div>
      </div>
    </section>
    <section>
      <GatsbyImage image={getImage(heroImage)} alt={title} />
    </section>
  </>
)

export default HeroSection
