import React from "react"
import { Link } from "gatsby"

const ContentSection = ({
  content,
  categories,
  keywords,
  tagline,
  recentNews,
}) => {
  return (
    <section>
      <div className="container mx-auto max-w-6xl px-6 py-28 grid grid-cols-1 lg:grid-cols-3 gap-y-12 lg:gap-x-12 lg:gap-y-0">
        <div className="col-span-2 space-y-6">
          <h2 className="font-medium text-3xl leading-relaxed tracking-tight">
            {tagline}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: content?.childMarkdownRemark?.html,
            }}
            className="single-news-content"
          />
          <div className="flex space-x-6 overflow-auto">
            {keywords.map((keyword, index) => (
              <button
                key={`single-news-keyword-${index}`}
                className="border px-6 py-3 text-sm hover:bg-blue-50 transition duration-300"
              >
                {keyword}
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col space-y-6">
          <div className="border p-8">
            <h3 className="text-gray-400">Recent News</h3>
            <div className="mt-6 space-y-5">
              {recentNews.map(({ id, title, slug, date }) => (
                <div key={id} className="space-y-1">
                  <Link to={`/news/${slug}`}>
                    <h4>{title}</h4>
                  </Link>
                  <span className="block text-sm text-gray-400">{date}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="border p-8">
            <h3 className="text-gray-400">Categories</h3>
            <div className="mt-6 space-y-2">
              {categories.map((category, index) => (
                <div key={`single-news-category-${index}`} className="">
                  {category}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentSection
