import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import HeroSection from "../components/SingleNewsPage/HeroSection"
import ContentSection from "../components/SingleNewsPage/ContentSection"
import OtherNewsNavigationSection from "../components/SingleNewsPage/OtherNewsNavigationSection"
import CallToActionSection from "../components/CallToActionSection"

const SingleNewsPage = ({ data }) => {
  const { singleNews, previousSingleNews, nextSingleNews } = data
  const recentNews = data?.recentNews?.nodes ?? []
  const {
    title,
    heroImage,
    author,
    date,
    tags,
    content,
    categories,
    keywords,
    tagline,
  } = singleNews

  return (
    <Layout>
      <Seo title={title} />
      <HeroSection
        title={title}
        heroImage={heroImage}
        author={author}
        date={date}
        tags={tags}
      />
      <ContentSection
        content={content}
        categories={categories}
        keywords={keywords}
        tagline={tagline}
        recentNews={recentNews}
      />
      <OtherNewsNavigationSection
        previousSingleNews={previousSingleNews}
        nextSingleNews={nextSingleNews}
      />
      <CallToActionSection />
    </Layout>
  )
}

export default SingleNewsPage

export const query = graphql`
  query ($id: String!, $previousId: String, $nextId: String) {
    recentNews: allContentfulSingleNews(
      filter: { id: { ne: $id } }
      sort: { fields: date, order: DESC }
      limit: 4
    ) {
      nodes {
        id
        title
        slug
        date(formatString: "DD MMM, YYYY")
      }
    }
    singleNews: contentfulSingleNews(id: { eq: $id }) {
      title
      author
      date(formatString: "DD MMM, YYYY")
      tags
      categories
      keywords
      tagline
      content {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    previousSingleNews: contentfulSingleNews(id: { eq: $previousId }) {
      title
      slug
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    nextSingleNews: contentfulSingleNews(id: { eq: $nextId }) {
      title
      slug
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
