import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TileContainer = ({ children }) => (
  <div className="group min-h-[300px] relative overflow-hidden select-none">
    {children}
  </div>
)

const TileBody = ({ children }) => (
  <div className="px-10 py-6 relative w-full h-full flex items-center">
    {children}
  </div>
)

const EmptyTile = ({ isPrevious }) => (
  <TileContainer>
    <div className="absolute w-full h-full bg-black" />
    <TileBody>
      <div>
        <h4 className="text-gray-400">No more articles</h4>
        <h2 className="mt-2 text-white text-3xl leading-relaxed">
          This is the {isPrevious ? "first" : "last"} article.
        </h2>
      </div>
    </TileBody>
  </TileContainer>
)

const NavigationTile = ({ isPrevious, singleNews }) =>
  !singleNews ? (
    <EmptyTile isPrevious={isPrevious} />
  ) : (
    <TileContainer>
      <div className="absolute w-full h-full">
        <GatsbyImage
          image={getImage(singleNews.image)}
          alt={singleNews.title}
          className="w-full h-full"
          objectPosition="center top"
        />
      </div>
      <div className="absolute w-full h-full bg-black bg-opacity-60 group-hover:bg-opacity-50 transition-colors duration-300" />
      <Link to={`/news/${singleNews.slug}`}>
        <TileBody>
          <div>
            <h4 className="text-gray-400">
              {isPrevious ? "Previous" : "Next"} article
            </h4>
            <h2 className="mt-2 text-white text-3xl leading-relaxed">
              {singleNews.title}
            </h2>
          </div>
        </TileBody>
      </Link>
    </TileContainer>
  )

const OtherNewsNavigationSection = ({ previousSingleNews, nextSingleNews }) => {
  return (
    <section>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <NavigationTile singleNews={previousSingleNews} isPrevious={true} />
        <NavigationTile singleNews={nextSingleNews} isPrevious={false} />
      </div>
    </section>
  )
}

export default OtherNewsNavigationSection
